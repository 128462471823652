.firstSection {
  height: 900px;
  width: 100%;
}

.inner-container {
  border-left: 1px solid #715b3e;
  border-right: 1px solid #715b3e;
  position: fixed;
  margin: 70px 139px 0px 150px;
  width: -webkit-fill-available;
  height: 100vw;
}

.TEXT-DES {
  z-index: 1000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  padding: 0px 149px 0px 160px;
  color: #c9ab81;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  top: 100px;
  width: -webkit-fill-available;
}

.section-white {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 27px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px;
  padding-bottom: 30px;
  background: #0f1d22;
  color: #c9ab81;

  .promo {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .inner-container {
    margin: 70px 35px 0px 35px;
    height: -webkit-fill-available !important;
  }

  .TEXT-DES {
    font-size: 37px !important;
  }
}
