
.header.scrolled {
  background-color: #0F1D22; /* Set the background color when scrolled */
}

.header {
  box-shadow: 0px 2px 17px -8px rgb(0 0 0 / 30%);
  background-color: transparent;
  height: 70px;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  z-index: 100;
  width: -webkit-fill-available;
  position: fixed;
  top: 0;
  border-bottom: 1px solid #715b3e;
  transition: background-color 0.3s ease;

  .logo {
    width: 150px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #715b3e;
  }

  .iconHeader {
    border-left: 1px solid #715b3e;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 0px 40px;
    height: 70px;
  }
}

.headerIcon {
  svg {
    width: 22px !important;
    height: 22px !important;
  }
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .headerMenuItem {
    font-size: 18px;
    color: #fff;
    cursor: pointer;
  }

  .headerMenuItem:hover {
      transition: opacity 1s ease-in-out;
      transition: 0.2s;
      border-bottom: 3px #715b3e;
      line-height: 1.6;
      border-bottom-style: double;
  }

  .menuActive {
    border-bottom: 3px #715b3e;
    line-height: 1.6;
    border-bottom-style: double;
  }
}

span.ant-menu-title-content {
  font-size: 25px;
}

@media (max-width: 450px) {
  .rightIconHeader {
    width: 35px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #715b3e;
  }
  .leftIconHeader {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    border-right: 1px solid #715b3e;
  }
  .header {
    height: 70px !important;
  }
}
