.footer {
  background-color: #0F1D22 !important;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #715b3e;

  .datafooter {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px;
  }
  .iconfooter {
    display: flex;
    gap: 20px;
    svg {
      width: 35px;
      height: 35px;
    }
  }
}
.CopyRights {
  height: 70px;
  background: #0F1D22;
  border-top: 1px solid #715b3e;
  color: #715b3e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
