.menupage {
  padding-top: 70px;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  background: #0F1D22;
}

.card {
  height: 400px;
  padding: 9%;
  img {
    width: -webkit-fill-available;
    height: 100%;
    // border-radius: 15px;
    box-shadow: 0px 2px 17px -8px rgb(0 0 0 / 30%);
  }
  .clicable-des {
    border-radius: 5px;
    background: #f5f4f4;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -40px;
    z-index: 10;
    position: relative;
    height: 90px;
    display: flex;
    justify-content: center;
    color: #8e8e84;
    cursor: pointer;
    font-size: 22px;
    align-items: center;
  }
}

.itembackground {
  width: 100%;
  height: 300px;
}

.menuDes {
  background: #f5f4f4;
  height: 100%;
}

.titlemenu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  margin-top: -50px;
}
.returnName {
  font-size: 25px;
  border-radius: 5px;
  background-color: #000000;
  color: #fff;
  padding: 30px 50px 30px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-price-contanier {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
.menu-item {
  margin-bottom: 6px;
  border-bottom: 1px solid #b4bac3;
  .title-menu {
    margin-left: 30px;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .price-menu {
    margin-right: 30px;
    font-family: "Lato", sans-serif;
    width: 80px;
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
span.ant-collapse-header-text {
  display: flex;
  justify-content: center;
  font-family: "Libre Caslon Text", serif;
  font-size: 18px;
}
.description-menu {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 450px) {
  .card {
    height: 180px;
  }
  .card .clicable-des {
    height: 60px;
    font-size: 16px;
  }
  .menupage {
    padding-bottom: 20px;
  }
  .CopyRights {
    font-size: 13px;
  }
  img.img-footer {
    width: 200px;
    height: 150px;
  }
  .footer {
    height: 300px;
  }
  .footer .datafooter {
    font-size: 18px;
  }
  .header {
    padding: 0px !important;
    flex-wrap: wrap;
    height: max-content;
    .iconHeader {
      padding: 10px;
    }
  }
  .header .logo {
    width: 50px;
    height: 70px;
    margin-right: 15%;
  }
  span.ant-menu-title-content {
    font-size: 20px !important;
  }
  .returnName {
    padding: 10px 20px 10px 20px;
  }
  .menu-item .title-menu {
    margin-left: 10px;
    font-size: 16px;
  }
  .menu-item .price-menu {
    margin-right: 10px;
    font-size: 16px;
  }
  .description-menu {
    margin-left: 10px;
  }
  .section-white .promo {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  img.promoImg {
    width: 90% !important;
  }
  .section-white {
    height: 100%;
    padding-bottom: 20px;
    font-size: 16px;
  }
  .firstSection {
    height: 500px !important;
  }
  .TEXT-DES {
    margin-top: -110%;
  }
}
