/* Slideshow */

.slideshow {
  margin-top: -4px !important;
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  background: #7e7e81;
  height: 500px;
  align-items: center;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.slide {
  display: inline-block;
  height: 400px;
  width: 33%;
  // row-gap: 20px;
  border-radius: 40px;
  margin-left: 20px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #002244;
}

@media (max-width: 450px) {
  .slide {
    display: inline-block;
    height: 200px;
    width: 50%;
    border-radius: 10px;
    margin-left: 20px;
  }
  .slideshow {
    height: 300px !important;
  }
}
